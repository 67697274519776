<template>
  <div class="container">
    <PublicHeader :totalName="$t('moneyAddr').add" />
    <div class="form">
      <div>{{ $t('moneyAddr').Currency }}：</div>
      <van-field
        v-model="Type"
        :placeholder="$t('moneyAddr').placeholder"
        disabled
        @click="show = true"
      />
      <div v-show="Type === 'USDT'">{{ $t('moneyAddr').ChainAddress }}：</div>
      <div class="addrType" v-show="Type === 'USDT'">
        <span
          class="item"
          :class="{ active: CurrencyUrlType === item }"
          v-for="(item, index) in list"
          :key="index"
          @click="CurrencyUrlType = item"
        >
          {{ item }}
        </span>
      </div>
      <div>{{ $t('moneyAddr').DepositAddress }}：</div>
      <van-field
        v-model="CurrencyUrl"
        :placeholder="$t('moneyAddr').WalletAddress"
      />
      <div>{{ $t('moneyAddr').qrcode }}：</div>
      <van-uploader
        class="img-box"
        preview-size="120"
        :preview-image="false"
        :after-read="justAfterRead"
      >
        <img class="upImage" v-if="QRCode" :src="QRCode" alt="" />
      </van-uploader>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        :confirm-button-text="$t('public').sure"
        :cancel-button-text="$t('public').cancel"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
    <van-button class="btn" type="info" round block color="#8e1473" @click="handleSub">{{
      $t('moneyAddr').submit
    }}</van-button>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import transactionApi from '@/api/transaction'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      CurrencyUrl: '',
      show: false,
      Type: '',
      CurrencyUrlType: 'ERC20',
      QRCode: '',
      list: ['ERC20', 'TRC20', 'OMNI'],
      columns: ['USDT', 'BTC', 'ETH']
    }
  },
  mounted() {
    this.updateMode = false
    if (this.$route.query.info) {
      this.updateMode = true
      const info = this.$route.query.info
      this.Id = info.Id
      this.CurrencyUrlType = info.CurrencyUrlType
      this.QRCode = info.QRCode
      this.Type = info.Type
      this.CurrencyUrl = info.CurrencyUrl
    }
  },
  methods: {
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('file', file.file)
      this.QRCode = await transactionApi.upImage(formdata)
    },
    onConfirm(value) {
      this.Type = value
      this.show = false
    },
    async handleSub() {
      if (this.Type === '') {
        this.$toast(this.$t('moneyAddr').tips)
        return false
      }
      if (this.Type === 'USDT' && this.CurrencyUrlType === '') {
        this.$toast(this.$t('moneyAddr').tips1)
        return false
      }
      if (this.CurrencyUrl === '') {
        this.$toast(this.$t('moneyAddr').tips2)
        return false
      }
      if (this.QRCode === '') {
        this.$toast(this.$t('moneyAddr').tips3)
        return false
      }
      const form = {
        Type: this.Type,
        CurrencyUrlType: this.CurrencyUrlType,
        CurrencyUrl: this.CurrencyUrl,
        QRCode: this.QRCode
      }
      if (this.updateMode) {
        await transactionApi.WalletAddressUpdate(this.Id, form)
        this.$toast(this.$t('moneyAddr').success)
      } else {
        await transactionApi.WalletAddressAdd(form)
        this.$toast(this.$t('moneyAddr').success)
      }

      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;

  .form {
    margin: 20px 10px;
    border-radius: 4px;
    background: #17181e;
    padding: 15px;
    div {
      font-size: 14px;
      margin: 10px 0;
    }
    .addrType {
      margin-bottom: 10px;
      display: flex;
      .item {
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #dddde9;
        color: #333;
        border-radius: 4px;
        margin: 0 10px;
      }
      .active {
        background: #fff;
        border: 1px solid #578af7;
      }
    }

    .van-field {
      border-radius: 5px;
    }
    .tips {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      span {
        font-weight: bold;
        color: #37bdbd;
        font-size: 16px;
      }
    }
  }
  .upImage {
    width: 120px;
    height: 120px;
  }
  .btn {
    margin: 30px auto 0;
    width: 95vw;
  }
}
</style>
